import styled from 'styled-components';
import {device} from '../../../components/Common/device';
import {SocialFacebook} from '@styled-icons/typicons/SocialFacebook'
import {SocialTwitter} from '@styled-icons/typicons/SocialTwitter'
import {SocialLinkedin} from '@styled-icons/typicons/SocialLinkedin'
import {SocialInstagram} from '@styled-icons/typicons/SocialInstagram'

export const ContactDetailsSection = styled.section`
    padding:100px 0px 0px;

    @media ${device.tablet} {
        padding:80px 5px 0px;
    }

`;
export const ContactDetailsLayout = styled.div`

`;
export const MapSectionWrapper = styled.div`
    padding-top:100px;
    @media ${device.tablet} {
        padding-top:80px;
    }
`;
export const ContactLayout = styled.div`
    display:flex;
    background: #fff5f3;
    border: 1px solid #ffece8;
    padding: 20px;
    border-radius: 4px;
    align-items: center;
    margin-bottom: 30px;

    img{
        height:35px;
        flex-shrink:0;
    }
    @media ${device.laptopM}{
        padding:10px;
    }
    @media ${device.tablet} {
        margin-bottom:30px;
    }

`;

export const ContactTextLayout = styled.div`
    margin-left:10px;

    h5{
        text-align:left;
        margin-bottom: 0px;
    }

    p{
        margin-bottom:0px;
        word-break:break-all;
    }
`;



export const SocialContainer = styled.div`
    text-align:left;
    @media ${device.tablet} {

    }
`;

export const Social = styled.a`
    margin-right:5px;
`;

export const InstaIcon = styled(SocialInstagram)`
    width: 32px;
    color: #fff;
    padding: 6px;
    border-radius:100%;
    margin-right:8px;
    background: #ff3c1c;
    transition:all .5s;
    :hover{
        background: none;
        color: #ff3c1c;
        border:2px solid #ff3c1c;
    }
`;

export const LinkedinIcon = styled(SocialLinkedin)`
    width: 32px;
    color: #fff;
    padding: 6px;
    border-radius:100%;
    margin-right:8px;
    background: #ff3c1c;
    transition:all .5s;
    :hover{
        background: none;
        color: #ff3c1c;
        border:2px solid #ff3c1c;
    }
`;

export const TwitterIcon = styled(SocialTwitter)`
    width: 32px;
    color: #fff;
    padding: 6px;
    border-radius:100%;
    margin-right:8px;
    background: #ff3c1c;
    transition:all .5s;
    :hover{
        background: none;
        color: #ff3c1c;
        border:2px solid #ff3c1c;
    }
`;

export const FbIcon = styled(SocialFacebook)`
    width: 32px;
    color: #fff;
    padding: 6px;
    border-radius:100%;
    margin-right:8px;
    background: #ff3c1c;
    transition:all .5s;
    :hover{
        background: none;
        color: #ff3c1c;
        border:2px solid #ff3c1c;
    }
`;



export const MapLayout = styled.div`


    @media ${device.laptop} {
        width:100%;
    }
`;

export const ImageHolder = styled.div`
    max-height:400px;

    map-img{
        max-height:400px;
    }

    @media ${device.tablet} {
        margin:0px -5px;
    }
`;
