import React from 'react';
import {ContactDetailsSection,ContactDetailsLayout,ContactLayout,
    ContactTextLayout,SocialContainer,Social,MapSectionWrapper,
    FbIcon,TwitterIcon,LinkedinIcon,InstaIcon,MapLayout,ImageHolder
} from './contactdetails.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';
import ContactForm from "../ContactForm";
import GatsImg from "gatsby-image";

const ContactDetails  = () => {

    const JSONData  = useStaticQuery(graphql`
    query {
        contactPage2Json{
            ContactDetails{
                ContactCol{
                    ContactIcon
                    ContactHeading
                    ContactText
                }
                MapImage{
                    childImageSharp{
                        fluid(quality: 100){
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
  `);
   const ContactData = JSONData.contactPage2Json.ContactDetails;
    return (
        <ContactDetailsSection id="ContactDetailsContainer">
            <Container>
                <ContactDetailsLayout>
                    <Row>
                        <Col lg={6}>
                            <h3>Quickly Contact Us</h3>
                            <Row>
                                {
                                    ContactData.ContactCol.map(item=>{
                                        return <Col md={6}>
                                            <ContactLayout>
                                                <img src={item.ContactIcon} alt="" />
                                                <ContactTextLayout>
                                                    <h5>
                                                        {item.ContactHeading}
                                                    </h5>
                                                    <p>
                                                        {item.ContactText}
                                                    </p>
                                                </ContactTextLayout>
                                            </ContactLayout>
        
                                        </Col>
                                    })
                                }
                            </Row>
                            <SocialContainer>
                                <Social href="/" aria-label="Facebook Link">
                                    <FbIcon/>
                                </Social>
                                <Social href="/" aria-label="Twitter Link">
                                    <TwitterIcon/>
                                </Social>
                                <Social href="/" aria-label="Linkedin Link">
                                    <LinkedinIcon/>
                                </Social>
                                <Social href="/" aria-label="Instagram Link">
                                    <InstaIcon/>
                                </Social>
                            </SocialContainer>
                        </Col>
                        <Col lg={6}>
                            <ContactForm />
                        </Col>
                    </Row>
                </ContactDetailsLayout>
            </Container>

            <MapSectionWrapper>
                <MapLayout>
                    <ImageHolder>
                        <GatsImg
                            fluid={ContactData.MapImage.childImageSharp.fluid}
                            alt=""
                            className="map-img"
                        />
                    </ImageHolder>
                </MapLayout>
            </MapSectionWrapper>
        </ContactDetailsSection>
    );
}
export default ContactDetails;
