import styled from 'styled-components';
import {device} from '../../../components/Common/device';

export const ContactFormSection = styled.section`

`;
export const ContactFormWrapper = styled.div`

`;
export const MapLayout = styled.div`
    background:#345ea1;
    flex-shrink:0;

    @media ${device.laptop} {
        width:100%;
    }

    iframe{
        min-height:500px;
        margin-bottom:0px;

        @media ${device.laptop} {
            min-height:350px;
        }
    }
`;
export const FormLayout = styled.div`

    @media ${device.laptop} {
        width:100%;
        margin:auto;
        padding:40px 0px 0px;
    }

    @media ${device.tablet} {
        width:100%;
        margin:auto;
        padding:40px 0px 0px;
    }
`;
export const FormHeading = styled.h3`

`;
export const FormText = styled.p`

`;
export const ContactForm = styled.div`

`;

export const  Form = styled.form`
    margin-bottom:0px;
`;
export const  FormGroup = styled.div`
    margin-bottom:20px;
`;
export const  InputText = styled.input`
    border: 1px solid #ccc;
    font-size: 14px;
    padding: 8px 10px;
    width: 100%;
    border-radius:4px;
`;
export const MessageTextarea = styled.textarea`
    border: 1px solid #ccc;
    font-size: 14px;
    padding: 8px 10px;
    width: 100%;
    min-height:100px;
    border-radius:4px;
`;
export const SubmitBtn = styled.button`
    font-size:14px;
    background: #ff3c1c;
    color: #fff;
    padding: 10px 40px;
    text-decoration: none;
    display: inline-block;
    border: none;
    border: 2px solid #ff3c1c;
    cursor: pointer;
    border-radius: 25px;
    
    :hover{
        text-decoration:none;
        color: #ff3c1c !important;
        background:none !important;
    }
    svg{
        width:17px;
        margin-left:10px;
    }
    :hover svg path{
        fill:#ff3c1c;
    }
`;

export const TextCenter = styled.div`
    text-align:left;
`;

export const ContactFormSpanSuccess = styled.span`
    color:green;
    font-size:14px;
`;

export const ContactFormSpanErr = styled.span`
    color:red;
    font-size:14px;
`;
